<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-12 m-12 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Quyết toán lái xe</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                                    <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div id="print-view" style="width: 1000px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                                <div style="height: 100px; display: flex; justify-content: space-between;">
                                    <div style="border-bottom: 1px solid #999; 
                                        width: 25%; display: flex;align-items: center; justify-content: center;
                                        border-top: 1px solid #999;border-left: 1px solid #999;">
                                        <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                    </div>
                                    <div style="border-bottom: 1px solid #999;
                                        border-left: 1px solid #999;
                                        border-top: 1px solid #999;
                                        border-right: 1px solid #999;
                                        width: 45%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        font-weight: bold;">
                                        <div class="title"><h2>Quyết toán chi phí lái xe</h2></div>
                                    </div>
                                    <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                        <div><span style="font-weight: bold;">Số: </span><span>{{entity.invoiceCode}}</span></div>
                                        <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.invoiceDate}}</span></div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column; padding: 10px;">
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nhân viên:</div>
                                        <div style="font-size: 16px;">{{ entity.staff.fullName }}</div>
                                    </div>
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nội dung:</div>
                                        <div style="font-size: 16px;">{{ entity.note }}</div>
                                    </div>
                                </div>
                                <div style="font-weight: bold;">Tổng hợp chi phí</div>
                                <div style="display: flex; flex-direction: column; padding: 10px; background-color: #F8EDE3;">
                                    <div style="display: flex;">
                                        <div style="display: flex; width: 50%;"> 
                                            <div style="width: 150px;">Tổng chi phí: </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalFee) }}</div>
                                        </div>
                                        <div style="display: flex; width: 50%;"> 
                                            <div style="width: 150px;">Tổng chi hộ: </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalPayOnBehalf) }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; padding-top: 10px;">
                                        <div style="display: flex; width: 50%;"> 
                                            <div style="width: 150px;">Lương lái xe: </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalSalary) }}</div>
                                        </div>
                                        <div style="display: flex; width: 50%;"> 
                                            <div style="width: 150px;">Tổng vé VETC: </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalVetc) }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; padding-top: 10px;">
                                        <div style="display: flex; width: 50%;"> 
                                            <div style="width: 150px;">Tổng phí dịch vụ: </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalService) }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; padding-top: 10px;">
                                        <div style="display: flex; width: 50%; align-items: center;"> 
                                            <div style="width: 150px;">Tổng tiền lái xe chi <br> (Chi phí + chi hộ): </div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalDriver) }}</div>
                                        </div>
                                        <div style="display: flex; width: 50%; align-items: center;"> 
                                            <div style="width: 150px;">Tổng quyết toán</div>
                                            <div style="font-weight: bold;">{{ formatNumber(entity.totalInvoice) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; margin: 10px auto;">
                                    <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                        <thead>
                                            <tr>
                                                <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                <th style="width:120px; padding: 5px 10px; border: 1px solid #e0e0e0;">Mã điều xe/Số con't</th>
                                                <th style="width:120px; padding: 5px 10px; border: 1px solid #e0e0e0;">Hàng hóa</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên phí</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Loại phí</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Dự toán</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Giải chi</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Quyết toán</th>
                                                <th style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">Ghi chú</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="(item, index) in details" :key="'item-' + index">
                                            <tr>
                                                <td style="text-align: center; padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                                <td colspan="8" style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">
                                                    <span style="font-weight:bold;padding-right: 10px;">{{'Mã điều động: ' + item.key}}</span>
                                                    <span style="font-weight:bold;padding-right: 10px;">{{ 'Lái xe: ' + item.driver.fullName }}</span>
                                                    <span style="font-weight:bold;">{{ 'Số xe: ' + item.vehicle.licensePlate }}</span>
                                                </td>
                                            </tr>
                                            <tr v-for="(obj, i) in item.list" :key="'detail' + i">
                                                <td style="text-align: center; padding: 5px 10px; border: 1px solid #e0e0e0;">{{i + 1}}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="padding-left: 20px;">{{ obj.orderDetail.serial }}</span></td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">{{ obj.orderDetail.goods.goodsName }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">{{ obj.fee.feeName }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">{{ getFeeType(obj.type) }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0; text-align: right;">{{ formatNumber(obj.estimateAmount) }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0; text-align: right;">{{ formatNumber(obj.explainAmount) }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0; text-align: right;">{{ formatNumber(obj.invoiceAmount) }}</td>
                                                <td style="width:190px; padding: 5px 10px; border: 1px solid #e0e0e0;">{{ obj.note }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style="display: flex;justify-content: space-between;padding: 20px 30px 50px 30px;">
                                    <div v-for="(item, index) in entity.approveList" :key="'approve-' + index" style="display: flex; flex-direction: column; width: 40%; font-size: 17px; text-transform: none;">
                                        <div style="font-weight: bold; text-align: center;">{{ item.title }}</div>
                                        <div style="padding-top: 50px; font-weight: bold; text-align: center;">{{ item.staff.fullName }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jobInvoiceService from '@/api/jobInvoiceService';
    import { mapActions } from 'vuex';
    import messageBox from '@/utils/messageBox';

    export default ({
        data() {
            return {
                id: 0,
                entity: { staff: { fullName: '' } },
                details: [],
                jobList: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.$route.query.type == 'job'){
                this.getByJobId();
            }
            else{
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            print () {
                this.$htmlToPaper('print-view');
            },

            getByJobId(){
                this.setLoading(true);
                jobInvoiceService.getByJobId(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.jobList = response.data.jobList;
                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        }); 
                        this.details.map((obj) => {
                            let job = this.jobList.filter(x => x.job.jobCode == obj.key)[0];
                            obj.driver = job.job.driver,
                            obj.vehicle = job.job.vehicle
                        })
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                jobInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.jobList = response.data.jobList;
                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        }); 
                        this.details.map((obj) => {
                            let job = this.jobList.filter(x => x.job.jobCode == obj.key)[0];
                            obj.driver = job.job.driver,
                            obj.vehicle = job.job.vehicle
                        })
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.job.jobCode]: [...(groups[item.job.jobCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },

            back(){
                this.$router.push('/job-invoice');
            }
        },
    })
</script>